<!--

 Usage:

   <yb-disclosure label="My Disclosed Content">
     One
     <br>
     Two
     <br>
     Three
   </div>

At first, the disclosure will show only 1 line of content, but user can disclose more

-->
<template>
  <div class="yb-disclosure">
    <div ref="label" :class="effectiveLabelWrapperClasses" @click.stop="click">
      <div :class="labelClasses">
        {{ label }}
      </div>
      <yb-icon v-show="needsDisclosure" :class="iconClasses" :icon="disclosed ? 'nav-arrow-down' : 'nav-arrow-right'" />
    </div>
    <div :class="disclosed ? wrapperClasses : 'overflow-hidden'" :style="{ maxHeight: disclosed ? 'initial' : disclosedHeight + 'px' }">
      <div ref="content" class="px-2">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    labelClasses: {
      type: String,
      default: 'text-base font-semibold flex-grow truncate'
    },
    labelWrapperClasses: {
      type: String,
      default: 'flex flex-row flex-nowrap items-center justify-between px-2 py-1 border rounded yb-border-content yb-header select-none'
    },
    wrapperClasses: {
      type: String,
      default: 'overflow-hidden'
    },
    iconClasses: {
      type: String,
      default: 'yb-button-icon-md'
    },
    initiallyDisclosed: {
      type: Boolean,
      default: false
    },
    disclosedHeight: {
      type: Number,
      default: 24
    },
    labelHidden: {
      type: Boolean,
      default: false
    },
    forcedDisclosure: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    // Adapted from: https://stackoverflow.com/questions/38148297/in-vue-js-can-a-component-detect-when-the-slot-content-changes

    const content = ref()
    const updated = ref(0)
    const mutationObserver = ref(null)

    // Goal: any observer on updated in computed properties will force recomputation of cached values on slot content update
    const update = () => {
      updated.value++
    }

    const connectMutationObserver = () => {
      mutationObserver.value = new MutationObserver(update)

      mutationObserver.value.observe(content.value, {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true
      })
    }

    const disconnectMutationObserver = () => {
      mutationObserver.value.disconnect()
    }

    onMounted(async () => {
      connectMutationObserver()
      update()
    })

    onUnmounted(() => {
      disconnectMutationObserver()
      update()
    })

    return {
      content,
      updated
    }
  },
  data() {
    return {
      disclosed: this.initiallyDisclosed
    }
  },
  computed: {
    clientHeight() {
      const { content } = this.$refs
      const { updated } = this
      return !!content && updated > 0 ? content.clientHeight : 40
    },
    labelHeight() {
      const { label } = this.$refs
      const { updated } = this
      return !!label && updated > 0 ? label.clientHeight : 40
    },
    needsDisclosure() {
      const { forcedDisclosure, disclosedHeight, clientHeight, updated } = this
      return forcedDisclosure || (updated > 0 && clientHeight > disclosedHeight)
    },
    effectiveLabelWrapperClasses() {
      const { needsDisclosure, labelHidden } = this
      const result = labelHidden ? ['invisible', 'h-0'] : [this.labelWrapperClasses]
      if (needsDisclosure) {
        result.push('cursor-pointer')
      }
      return result
    }
  },
  methods: {
    click() {
      const { needsDisclosure } = this
      if (needsDisclosure) {
        this.disclosed = !this.disclosed
      }
    }
  }
}
</script>
